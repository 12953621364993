<template>
  <v-container ref="card" fluid ma-0 pl-3 pr-3 py-5>
    <v-row
      no-gutters
      class="d-inline-flex flex-nowrap" align="start" justify="space-between"
      style="width:100%"
      @click="itemClicked()"
    >
      <v-col cols="11" class="pe-4">
        <!-- Item title -->
        <div
          style="color: rgba(#535353, 0.6)"
          class="pb-2 listTitle"
          :class="{smallHeadline: true, selected: isSelected}"
        >
          {{ item.title }}
        </div>

        <VueReadMoreSmooth :lines="2" :max-lines="3" class="pb-3">
          <div
            :key="descriptionKey"
            style="font-weight: 200;font-size: calc(.8 * var(--v-theme-font-size));line-height: var( --v-theme-description-featured-line-height);word-break: break-word;" v-html="itemDescription">
          </div>
          <template v-slot:more="value">
            <div
              class="pt-1 storyText noscale"
              style="color: var(--v-accent-lighten1); font-weight: 700; font-size: calc(.7 * var(--v-theme-font-size));  font-family:var(--v-theme-font);"
            >
              {{ value.open ? $t("show_less") : $t("show_more") }}
            </div>
          </template>
        </VueReadMoreSmooth>

        <!-- Date and type -->
        <v-container pa-0 ma-0>
          <v-row class="date justify-start align-center d-flex dateContain" no-gutters>
            <v-col class="flex-grow-0 my-0 d-flex align-center" style="max-height:12px">
              <v-icon
                size="12px"
                v-if="isSelected"
                dense
                class="selected  "
                color="green"
                >$vuetify.icons.typeAudioPlaying</v-icon
              >

              <v-icon size="12px" v-else dense>$vuetify.icons.play</v-icon>
            </v-col>
            <v-col class="flex-grow-0 mx-2 dateContain">
              <div>
                {{ item.mediaDuration | timeInColonFormat }}
              </div>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col class="flex-shrink-0 mx-2 dateContain">
              <DateView :date="item.pubDate" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-spacer></v-spacer>
      <!-- fav button-->
      <v-col cols="auto" ma-0 pa-0>
        <ItemFavoriteButton
          :item="item"
          mediaType="audio"
          class="justify-end"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ItemBase from "./ItemBase"
  import ItemFavoriteButton from "./ItemFavoriteButton"
  import DateView from "./DateView"
  import VueReadMoreSmooth from "./ReadMore.vue"

  export default {
    extends: ItemBase,
    components: {
      DateView,
      ItemFavoriteButton,
      VueReadMoreSmooth,
    },
    props: {
      isSelected: {
        type: Boolean,
        default: function() {
          return false
        },
      },
    },
    data: () => {
      return {
        showAll: false,
        descriptionKey: 0,
      }
    },
    computed: {
        itemDescription() {
            if (this.item != null && this.item.description) {
                let description = this.item.description;
                const brIndex = description.indexOf('<br');

                return brIndex !== -1 ? description.substring(0, brIndex).trim() : description.trim();
            }
            return "";
        },
    },
    methods: {
      showMoreClick() {
        this.showAll = !this.showAll
      },
      itemClicked() {
        this.$store.commit("setMediaPlayerItem", this.item)
        this.$emit("itemClicked", {
          item: this.item,
          rect: this.$refs.card.getBoundingClientRect(),
        })
        this.$root.mediaPlayerDocked = true
      },
    },
    filters: {
      timeInColonFormat: function(value) {
        let hours = parseInt(Math.floor(value / 3600))
        let minutes = parseInt(Math.floor((value - hours * 3600) / 60))
        let seconds = parseInt((value - (hours * 3600 + minutes * 60)) % 60)

        let dHours = hours
        let dMins = minutes > 9 ? minutes : "0" + minutes
        let dSecs = seconds > 9 ? seconds : "0" + seconds
        if (hours > 0) {
          return dHours + ":" + dMins + ":" + dSecs
        }
        return dMins + ":" + dSecs
      },
    },
  }
</script>

<style scoped>
  .date {
    max-height: var(--v-theme-date-font-size);
  }
  .selected {
    color: #000 !important;
  }
</style>
