<template>
<v-container ref="card" fluid ma-0 pl-3 pr-3 pt-3 pb-2 @click="itemClicked()">
    <v-row no-gutters nowrap class="align-baseline">
        <v-col cols="1">
            <!-- Date and type -->
            <v-icon v-if="isSelected" class="selected ma-0 pa-0" small>$vuetify.icons.typeAudioPlaying</v-icon>
            <v-icon v-else class="ma-0 pa-0 me-1 tiny" small>$vuetify.icons.typeAudio</v-icon>
            <span v-if="disabled" class="date verticalCenter">{{ $t('no_live_show') }}</span>
            <!-- <DateView class="date verticalCenter" :date="item.pubDate" ago /> -->
        </v-col>
        <v-col>
            <!-- Item title -->
            <div :class="{smallHeadline: true, smallHeadline2lines: true, selected: isSelected, disabled: disabled}">{{ $t("live_show") }}

            </div>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import ItemBase from "./ItemBase";

export default {
    extends: ItemBase,
    props: {
        isSelected: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default: function () {
                return false;
            }
        }
    },
    methods: {
        itemClicked() {
          this.$store.commit("setMediaPlayerItem", this.item);
            this.$emit("itemClicked", {
                item: this.item,
                rect: this.$refs.card.getBoundingClientRect()
            });
        }
    }
};
</script>

<style scoped>
.disabled {
    color: var(--v-secondary-base) !important;
}

.selected {
    color: #637568 !important;
}
</style>
