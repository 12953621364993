<template>
    <div class="ma-0 pa-2">
        <v-item-group ref="card">
            <v-lazy ma-0 pa-0 transition="fade-transition" min-height="60px">
                <v-container fluid fill-height ma-0 px-2 pa-0>
                    <v-row>
                        <v-col ref="card" v-for="(categoryObj, index) in uniqueCategories"
                            :key="categoryObj.category"
                            @click="itemClicked(categoryObj, index)"
                            style="background-color: inherit" :cols="6" md="4">
                            <v-img :aspect-ratio="1" :src="categoryObj.imageUrl"
                                @error="$logger.logFetchError(categoryObj.imageUrl)"
                                class="mx-auto pa-0 podimg" />
                            <div class="pt-1 podTitle verticalCenter text-center">{{
                                categoryObj.category }}</div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-lazy>
        </v-item-group>
    </div>
</template>

<script>
import ItemListBase from "./ItemListBase";

export default {
    extends: ItemListBase,
    props: {
        uniqueCategories: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        itemClicked(categoryObj, index) {
            console.log("this has been clicked");
            const item = this.$store.state.currentFeedAudio?.items?.find((item) => item.category === categoryObj.category);
            this.selectedCategory = categoryObj;
            console.log("selectedCategory set to:", this.selectedCategory);
            this.$emit("selectedCategory", categoryObj);
            this.$emit("itemClicked", {
                item,
                index,
                rect: this.$refs.card[index].getBoundingClientRect(),
            });
        }
    },

}
</script>
